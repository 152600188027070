.login-form__submit:disabled,
.login-form__submit[disabled] {
  background-color: #cccccc;
  color: #666666;
}

.login-form-error {
  color: red;
  max-width: 277px;
  padding: 20px 0 20px 0;
  text-align: center;
}

.entry__action {
  height: 2.7rem;
  width: 2.7rem;
}

.child-entry__col {
  padding: 0rem 2rem 0rem 2rem;
}

.child-entry-container {
  overflow-y: scroll;
}

/* custom scrollbar */
.entries-container ::-webkit-scrollbar,
.cards-container ::-webkit-scrollbar {
  width: 20px;
}

.entries-container ::-webkit-scrollbar-track,
.cards-container ::-webkit-scrollbar-track {
  background-color: transparent;
}

.entries-container ::-webkit-scrollbar-thumb,
.cards-container ::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
.entries-container ::-webkit-scrollbar-thumb:hover,
.cards-container ::-webkit-scrollbar-thumb:hover {
  background-color: #bad5df;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.popup-save-radio input[type="radio"] {
  -webkit-appearance: radio;
}

.popup-save-radio input[type="checkbox"] {
  -webkit-appearance: checkbox;
}

.popup-save-radio {
  margin-top: 18px;
  text-align: left;
  width: 100%;
}

.entries-list {
  overflow: hidden;
  max-height: 26rem;
  transition: all 0.2s ease;
}

.entries-list-entry2 {
  max-height: 5rem;
  height: 5rem;
}

.svg-pan-zoom_viewport {
  transition: transform 0.1s ease-out;
}
@media (min-width: 1300px) {
  .card__content {
    padding-top: 0px;
  }
}
.LoadingBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: black;
}

.LoadingBar-progress {
  height: 5px;
  width: 100%;
  border-radius: 0px;
  background: linear-gradient(
    90deg,
    #d42929 0%,
    #f76b1c 15%,
    #ffbb00 30%,
    #22b82b 50%,
    #008ef8 65%,
    #702aee 80%,
    #d42929 100%
  );
  background-size: 200%;
  animation: moveGradient 2s linear infinite;
}

@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -200% 0%;
  }
}

.saved-search-select {
  height: 3.8rem;
  border-radius: 8px;

  margin-bottom: 1.6rem;

  width: 100%;
  color: rgba(28, 28, 28, 0.45);
}

/* DivTable.com */
.divTable {
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  border: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;
  text-align: center;
  width: 10rem;
  font-size: 1rem;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}

.updates-container {
  overflow: hidden;
  max-height: 44rem;
}

.update {
  height: 8rem;
}

.nowrap {
  white-space: nowrap;
}
.calendar-presets {
  margin-top: 10px;
}
.calendar-presets a,
.calendar-presets button {
  display: block;
  margin-top: 0px;
  margin-bottom: 20px;

  width: 100%;
  margin-left: 5px;
  padding: 4px 0.8rem;
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  background-color: #203864;
  color: white;
  cursor: pointer;
  border: unset;
  border-radius: 5px;
  min-width: 140px;
  text-align: left;
  font-weight: bold;
}

.calendar-presets a:hover {
  background-color: #31569b;
  color: white;
}

.entry__title {
  cursor: pointer;
}
.entry__title:hover {
  text-decoration: underline;
}

.entry__tag {
  cursor: pointer;
}

.entry__tag:hover {
  background-color: #3f70b1;
}

.no-underline {
  text-decoration: none;
}

.saved-search-date {
  color: grey;
  font-size: 0.9rem;
  right: 40px;
  position: absolute;
  bottom: 8px;
}

.Toastify__progress-bar--default {
  background: linear-gradient(
    90deg,
    #d42929 0%,
    #f76b1c 15%,
    #ffbb00 30%,
    #22b82b 50%,
    #008ef8 65%,
    #702aee 80%,
    #d42929 100%
  ) !important;
}

.Toastify__toast--default {
  border: 1px solid black !important;
  border-radius: 5px !important;
  padding: 10px !important;
  background-color: #fff !important;
  font-family: "Lato", sans-serif !important;
}

.cloud-svg-main {
  width: 100%;
  height: 400px;
  cursor: pointer;
  font-size: unset;
  color: unset;
  text-align: center;
}

.cloud-svg-wrapper {
  width: 550px;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin-left: 20px;
  margin-top: 40px;
}


@media (max-width: 1299px) {
  .cloud-svg-wrapper {
    margin-left: 20px;
    margin-top: 60px;
  }
}


@media (min-width: 1300px) {
  .cloud-svg-wrapper {
    width: 550px;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin-left: 30px;
    margin-top: 20px;
  }
}

@media (min-width: 2000px) {
  .cloud-svg-wrapper {
    width: 80%;
    height: 90%;
    margin-left: 40px;
    margin-top: 20px;
  }
}

.date-range-btn {
  display: block;

  padding: 3px 0.8rem;
  margin: 0 5px;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  background-color: #203864;
  color: white;
  cursor: pointer;
  border: 1px solid #203864;
  border-radius: 5px;
  font-weight: bold;
}

.main-calendar {
  width: 440px;
  display: flex;
  margin-right: 40px;
}
@media (max-width: 1450px) {
  .main-calendar {
    margin-right: 0px;
  }
}
@media (max-width: 1450px) {
  .card--40md {
    width: calc(40% - 2.5rem) !important;
  }
  .card--45md {
    width: calc(45% - 2.5rem) !important;
  }
  .card--55md {
    width: calc(55% - 2.5rem) !important;
  }
  .card--60md {
    width: calc(60% - 2.5rem) !important;
  }

  .card--100md {
    width: calc(100% - 2.5rem) !important;
    height: 54rem;
  }
}

.no-results {
  margin-top: 40px;
  font-size: 1.4rem;
}
.no-results p {
  margin-top: 10px;
}

.no-results a {
  color: #203864;
  text-decoration: none;
}

.no-results a:visited {
  color: #203864;
  text-decoration: none;
}

.no-results a:hover {
  color: #203864;
  text-decoration: underline;
}

a.custom-style {
  color: #203864;
  text-decoration: none;
}

a.custom-style:visited {
  color: #203864;
  text-decoration: none;
}

a.custom-style:hover {
  color: #203864;
  text-decoration: underline;
}

.all-tags .card-row {
  display: grid;
  margin-bottom: 5rem;
  grid-template-columns: unset;
  grid-column-gap: 3rem;
  margin-bottom: unset;
}
.all-tags .card-row__text {
  columns: 5;
  line-height: 3rem;
}
/*tooltip*/
.type-dark {
  max-width: 400px;
}

.my-profile-icon {
  vertical-align: middle;
  height: 32px;
  margin-right: 10px;
}
